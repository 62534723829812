<template>
  <div class="form-check">
    <input type="checkbox" class="form-check-input" :id="inputId" v-model="checked">
    <label class="form-check-label" :for="inputId">{{ label }}</label>
  </div>
</template>

<script>
import inputMixin from './inputMixin'

export default {
  mixins: [
    inputMixin
  ],
  data () {
    return {
      checked: false
    }
  },
  created () {
    this.checked = this.value
  },
  watch: {
    checked (val) {
      this.$emit('value-change', val)
    }
  }
}
</script>
